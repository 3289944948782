<template>
  <div class="container mt-4">
    <h2 class="mb-4">Manage Ads</h2>
    <div v-if="ads.length === 0" class="alert alert-info">No ads available at the moment.</div>
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>#</th>
          <th>Ad Title</th>
          <th>Image</th>
          <th>Visible</th>
          <th>Created At</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ad in ads" :key="ad.id">
          <td>{{ ad.id }}</td>
          <td>{{ ad.title }}</td>
          <td>
            <img v-if="ad.image" :src="ad.image" alt="Ad Image" class="img-thumbnail" width="100" />
          </td>
          <td>
            <span v-if="ad.visible" class="badge bg-success">Yes</span>
            <span v-else class="badge bg-secondary">No</span>
          </td>
          <td>{{ formatDate(ad.created_at) }}</td>
          <td>
            <button class="btn btn-sm btn-primary me-2" @click="toggleVisibility(ad)">
              {{ ad.visible ? "Hide" : "Show" }}
            </button>
            <button class="btn btn-sm btn-danger" @click="deleteAd(ad.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiClient from "../utils/apiClient";

export default {
  name: "ViewAds",
  data() {
    return {
      ads: [], // Ads data from the backend
    };
  },
  async mounted() {
    this.fetchAds();
  },
  methods: {
    async fetchAds() {
      try {
        const response = await apiClient.get("/backend/ads/");
        this.ads = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.error("Error fetching ads:", error.message);
        this.ads = [];
      }
    },
    formatDate(value) {
      if (!value) return "N/A"; // Handle empty or null dates
      const options = { year: "numeric", month: "short", day: "numeric" }; // Example: Dec 2, 2024
      return new Date(value).toLocaleDateString(undefined, options);
    },
    async toggleVisibility(ad) {
  try {
    // Send only the `visible` field in the request
    const response = await apiClient.patch(`/backend/ads/${ad.id}/`, {
      visible: !ad.visible,
    });

    // Update the UI
    ad.visible = response.data.visible;

    // Success message
    this.$swal.fire({
      title: "Success",
      text: `Ad visibility updated to ${ad.visible ? "Visible" : "Hidden"}.`,
      icon: "success",
    });
  } catch (error) {
    console.error("Error toggling visibility:", error.response?.data || error.message);

    // Show error message
    this.$swal.fire({
      title: "Error",
      text: "Failed to toggle visibility. Please try again.",
      icon: "error",
    });
  }
}
,
    async deleteAd(id) {
      try {
        const confirm = await this.$swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        });

        if (confirm.isConfirmed) {
          await apiClient.delete(`/backend/ads/${id}/`);

          // Update the UI
          this.ads = this.ads.filter((ad) => ad.id !== id);

          this.$swal.fire({
            title: "Deleted!",
            text: "Ad has been deleted successfully.",
            icon: "success",
          });
        }
      } catch (error) {
        console.error("Error deleting ad:", error.message);
        this.$swal.fire({
          title: "Error",
          text: "Failed to delete the ad. Please try again.",
          icon: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.img-thumbnail {
  object-fit: cover;
}
.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}
</style>
