import Swal from "sweetalert2";

export default {
  install(app) {
    app.config.globalProperties.$swal = Swal;

    // Add custom methods for common alerts
    app.config.globalProperties.$showSuccess = (message, title = "Success") => {
      Swal.fire({
        title: title,
        text: message,
        icon: "success",
        confirmButtonText: "OK",
      });
    };

    app.config.globalProperties.$showError = (message, title = "Error") => {
      Swal.fire({
        title: title,
        text: message,
        icon: "error",
        confirmButtonText: "OK",
      });
    };

    app.config.globalProperties.$showConfirm = async (message, title = "Are you sure?") => {
      const result = await Swal.fire({
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      return result.isConfirmed;
    };
  },
};
