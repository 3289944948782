<template>
    <div>
      <h2>View Products</h2>
      <div v-if="products.length === 0" class="alert alert-info">
        No products available. Add a product to get started!
      </div>
      <div class="row">
        <div class="col-md-4" v-for="product in products" :key="product.id">
          <div class="card mb-3">
            <img
              v-if="product.image"
              :src="product.image"
              class="card-img-top"
              alt="Product Image"
            />
            <div class="card-body">
              <h5 class="card-title">{{ product.name }}</h5>
              <p class="card-text">{{ product.description }}</p>
              <p><strong>Price:</strong> ${{ product.price }}</p>
              <button
                class="btn btn-success"
                :class="{ 'btn-secondary': product.visible }"
                @click="toggleVisibility(product)"
              >
                {{ product.visible ? "Hide from Website" : "Show on Website" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ViewProducts",
    data() {
      return {
        products: [],
      };
    },
    async mounted() {
      this.fetchProducts();
    },
    methods: {
      async fetchProducts() {
        try {
          const token = localStorage.getItem("accessToken");
          const response = await axios.get("http://127.0.0.1:8000/backend/products/", {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.products = response.data;
        } catch (error) {
          console.error("Failed to fetch products:", error.response?.data || error.message);
        }
      },
      async toggleVisibility(product) {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      alert("You must log in first.");
      return;
    }

    // Toggle the visible status
    const updatedVisibility = !product.visible;

    console.log("Updating visibility to:", updatedVisibility); // Debugging

    const response = await axios.patch(
      `http://127.0.0.1:8000/backend/products/${product.id}/`,
      { visible: updatedVisibility }, // Send updated visibility
      {
        headers: { Authorization: `Bearer ${token}` }, // Include the token
      }
    );

    // Update the local product's visibility
    product.visible = response.data.visible;

    alert(
      `Product visibility updated: ${
        product.visible ? "Visible on Website" : "Hidden from Website"
      }`
    );
  } catch (error) {
    console.error("Failed to update visibility:", error.response?.data || error.message);
    alert("Failed to update visibility. Please try again.");
  }
}

,
    },
  };
  </script>
  