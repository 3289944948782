<template>
    <div>
      <h2>Manage Ads</h2>
      <form @submit.prevent="addAd">
        <div class="mb-3">
          <label for="title" class="form-label">Ad Title</label>
          <input
            v-model="newAd.title"
            type="text"
            class="form-control"
            id="title"
            placeholder="Enter ad title"
            required
          />
        </div>
        <div class="mb-3">
          <label for="image" class="form-label">Ad Image</label>
          <input type="file" class="form-control" @change="onFileChange" />
        </div>
        <button type="submit" class="btn btn-primary">Add Ad</button>
      </form>
    </div>
  </template>
  
  <script>
  import apiClient from "../utils/apiClient"; // Adjust the path based on your folder structure

  export default {
    data() {
      return {
        newAd: {
          title: "",
          image: null,
        },
      };
    },
    methods: {
      onFileChange(event) {
        this.newAd.image = event.target.files[0];
      },
      async addAd() {
  try {
    const formData = new FormData();
    formData.append("title", this.newAd.title);
    if (this.newAd.image) {
      formData.append("image", this.newAd.image);
    }

    // API call
    await apiClient.post("/backend/ads/", formData);

    // Reset form
    this.newAd = { title: "", image: null };

    // Success message
    this.$swal.fire({
      title: "Success!",
      text: "Ad has been added successfully.",
      icon: "success",
    });
  } catch (error) {
    console.error("Error adding ad:", error);
    this.$swal.fire({
      title: "Error!",
      text: "Failed to add ad. Please try again.",
      icon: "error",
    });
  }
}

    },
  };
  </script>
  