<template>
    <div>
      <h2>Add Product</h2>
      <form @submit.prevent="addProduct" enctype="multipart/form-data">
        <div class="mb-3">
          <label for="name" class="form-label">Product Name</label>
          <input
            v-model="product.name"
            type="text"
            class="form-control"
            id="name"
            required
          />
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">Description</label>
          <textarea
            v-model="product.description"
            class="form-control"
            id="description"
            required
          ></textarea>
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">Price</label>
          <input
            v-model="product.price"
            type="number"
            class="form-control"
            id="price"
            required
          />
        </div>
        <div class="mb-3">
          <label for="image" class="form-label">Image</label>
          <input
            type="file"
            class="form-control"
            @change="onFileChange"
          />
        </div>
        <button type="submit" class="btn btn-primary">Add Product</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { refreshAccessToken } from "../utils/auth";


  export default {
    name: "AddProduct",
    data() {
      return {
        product: {
          name: "",
          description: "",
          price: 0,
          image: null,
        },
      };
    },
    methods: {
      onFileChange(event) {
        this.product.image = event.target.files[0];
      },
      async addProduct() {
        const token = await refreshAccessToken();
        if (token) {
        try {
          const token = localStorage.getItem("accessToken");
          const formData = new FormData();
          formData.append("name", this.product.name);
          formData.append("description", this.product.description);
          formData.append("price", this.product.price);
          if (this.product.image) {
            formData.append("image", this.product.image);
          }
  
          await axios.post("http://127.0.0.1:8000/backend/products/", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
  
          alert("Product added successfully!");
          this.product = { name: "", description: "", price: 0, image: null }; // Reset form
        } catch (error) {
          console.error("Failed to add product:", error.response?.data || error.message);
          alert("Failed to add product. Please try again.");
        }
        }
      },
    },
  };
  </script>
  