<template>
  <div class="container mt-5">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input
          v-model="username"
          type="text"
          id="username"
          class="form-control"
          placeholder="Enter your username"
          required
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input
          v-model="password"
          type="password"
          id="password"
          class="form-control"
          placeholder="Enter your password"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AdminLogin',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
  try {
    const response = await axios.post("http://127.0.0.1:8000/api/token/", {
      username: this.username,
      password: this.password,
    });

    // Save tokens to localStorage
    localStorage.setItem("accessToken", response.data.access);
    localStorage.setItem("refreshToken", response.data.refresh);

    alert("Login successful!");
    this.$router.push("/dashboard");
  } catch (error) {
    console.error("Login failed:", error.response?.data || error.message);
    alert("Invalid credentials. Please try again.");
  }
}

  },
};
</script>

<style scoped>
.container {
  max-width: 400px;
  margin: auto;
}
</style>
