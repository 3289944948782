// src/utils/auth.js

import axios from "axios";

// Base URL of your backend
const BASE_URL = "http://127.0.0.1:8000";

export async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token available.");
    }

    // Request a new access token using the refresh token
    const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
      refresh: refreshToken,
    });

    // Store the new access token
    const newAccessToken = response.data.access;
    localStorage.setItem("accessToken", newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error.response?.data || error.message);
    alert("Session expired. Please log in again.");
    // Clear tokens and redirect to login
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    return null;
  }
}

export function logout() {
  // Clear tokens and optionally redirect to the login page
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  alert("You have been logged out.");
  window.location.href = "/login"; // Adjust path if using Vue Router
}
