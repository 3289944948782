import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap";


import swalPlugin from "./plugins/swal";



const app = createApp(App);
app.use(router);
app.use(swalPlugin); // Register the SweetAlert2 plugin
app.mount("#app");
