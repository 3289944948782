import { createRouter, createWebHistory } from 'vue-router';
import WebsiteHome from '../components/WebsiteHome.vue';
import AdminLogin from '../components/AdminLogin.vue';
import AboutUs from '../components/AboutUs.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import AddProduct from "../components/AddProduct.vue";
import ViewProducts from "../components/ViewProducts.vue";
import ManageAds from "../components/ManageAds.vue";
import ViewAds from "../components/ViewAds.vue";
import ManageFavorites from "../components/ManageFavorites.vue";
import products from "../components/products.vue";
import achievements from "../components/achievements.vue";
import contact from "../components/contact.vue";
import CompanyStructue from "../components/CompanyStructue.vue";


const routes = [
  { path: '/', component: WebsiteHome }, // Home Page
  { path: '/login', component: AdminLogin }, // Login Page
  { path: '/aboutus', component: AboutUs }, // AboutUs 
  { path: '/products', component: products }, // products 
  { path: '/achievements', component: achievements }, // achievements 
  { path: '/contact', component: contact }, // contact 
  { path: '/companystructue', component: CompanyStructue }, // companystructue 
  
  {
    path: '/dashboard',
    component: AdminDashboard, // Dashboard Page
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        alert('You must log in to access the dashboard.');
        next('/login');
      } else {
        next();
      }
    },
    children: [
      { path: "add", component: AddProduct },
      { path: "view", component: ViewProducts },
      { path: "manage-ads", component: ManageAds }, // Add Manage Ads route
      { path: "view-ads", component: ViewAds }, 
      {
        path: "/manage-favorites",
        component: ManageFavorites,
        meta: { requiresAuth: true },
      },// Add View Ads route


    ],
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
