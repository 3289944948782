<template>
    <div>
      <h2>Manage Favorite Products</h2>
      <div v-if="products.length === 0" class="alert alert-info">
        No products available.
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Price</th>
            <th>Favorite</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.description }}</td>
            <td>${{ product.price }}</td>
            <td>
              <span v-if="product.is_favorite" class="badge bg-success">Yes</span>
              <span v-else class="badge bg-secondary">No</span>
            </td>
            <td>
              <button
                class="btn btn-sm"
                :class="product.is_favorite ? 'btn-danger' : 'btn-primary'"
                @click="confirmToggleFavorite(product)"
              >
                {{ product.is_favorite ? 'Remove Favorite' : 'Mark Favorite' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "../utils/apiClient";
  
  export default {
    name: "ManageFavorites",
    data() {
      return {
        products: [],
      };
    },
    async mounted() {
      this.fetchProducts();
    },
    methods: {
      async fetchProducts() {
        try {
          const response = await axios.get("/backend/products/");
          this.products = response.data; // Fetch all products
        } catch (error) {
          console.error("Failed to fetch products:", error.response?.data || error.message);
        }
      },
      async confirmToggleFavorite(product) {
        // Show confirmation dialog
        const result = await this.$swal.fire({
          title: "Are you sure?",
          text: `Do you want to ${product.is_favorite ? "remove" : "mark"} "${product.name}" as a favorite?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
  
        // If confirmed, toggle favorite
        if (result.isConfirmed) {
          this.toggleFavorite(product);
        }
      },
      async toggleFavorite(product) {
        try {
          const response = await axios.patch(`/backend/products/${product.id}/toggle-favorite/`);
          product.is_favorite = response.data.is_favorite; // Update locally
  
          // Show success notification
          this.$swal.fire({
            title: "Success!",
            text: `Product "${product.name}" is now ${product.is_favorite ? "a favorite" : "not a favorite"}.`,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error("Failed to toggle favorite:", error.response?.data || error.message);
  
          // Show error notification
          this.$swal.fire({
            title: "Error",
            text: "Failed to update favorite status. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .table {
    margin-top: 20px;
  }
  </style>
  