<template>
  <div class="dashboard">
    <!-- Sidebar -->
    <div class="sidebar">
      <router-link to="/dashboard/add" class="sidebar-link">Add Product</router-link>
      <router-link to="/dashboard/view" class="sidebar-link">View Products</router-link>
      <router-link to="/dashboard/manage-ads" class="sidebar-link">add Ads</router-link>
      <router-link to="/dashboard/view-ads" class="sidebar-link">View Ads</router-link>
      <router-link to="/manage-favorites" class="sidebar-link">Manage Favorites</router-link>


    </div>

    <!-- Main Content -->
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminDashboard",
};
</script>

<style scoped>
.dashboard {
  display: flex;
  height: 100vh;
}
.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 20px;
}
.sidebar-link {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}
.main-content {
  flex-grow: 1;
  padding: 20px;
}
</style>
