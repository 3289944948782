import axios from "axios";
import { refreshAccessToken, logout } from "./auth";

// Create an Axios instance
const apiClient = axios.create({
  baseURL: "http://127.0.0.1:8000", // Adjust this to your backend URL
});

// Request interceptor to add Authorization header and handle token refresh
apiClient.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("accessToken");

    // If token is missing or needs refreshing
    if (!token) {
      token = await refreshAccessToken();
      if (!token) {
        logout();
        throw new Error("Session expired. Redirecting to login...");
      }
    }

    // Attach the token to the Authorization header
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
