<template>
  <div id="app">
    <!-- Navigation -->
    <!-- <header class="navbar bg-light p-3">
      <div class="container">
        <router-link to="/" class="btn btn-primary">Home</router-link>
        <router-link v-if="!isAuthenticated" to="/login" class="btn btn-primary ms-2">Login</router-link>
        <router-link v-if="isAuthenticated" to="/dashboard" class="btn btn-success ms-2">Dashboard</router-link>
        <button v-if="isAuthenticated" @click="logout" class="btn btn-danger ms-2">Logout</button>
      </div>
    </header> -->

    <!-- Main Content -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    isAuthenticated() {
      return !!localStorage.getItem('accessToken'); // Check if user is logged in
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.$router.push('/login');
    },
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  margin: 0;
}
</style>
